<template>
    <el-dialog v-model="mapVisible" :lock-scroll="true" :before-close="close" width="650px" custom-class="amap">
        <template v-slot:title>
            <div class="flex_c">
                <p class="fs_18 bold">工作地点 <span class="el-icon-loading" v-if="showLoading"></span></p>
            </div>
        </template>
        <div class="content">
            <el-form :model="form" :rules="rules" ref="addressForm" :hide-required-asterisk="true" label-width="100px" class="addressForm">
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="街道 / 写字楼" prop="formattedAddress">
                            <div id="amap-container">
                                <!-- <input type="hidden" v-model="form.address"> -->
                                <el-input id="search-input" v-model.trim="form.formattedAddress" class="input-with" autocomplete="off" placeholder="请输入地址" @input="addressInput" @clear="handelclearInput" @keyup.enter="handelSearch"></el-input>
                                <!-- 远程搜索 -->
                                <div id="searchResultPanel" />
                            </div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="门牌号" prop="room_number">
                            <el-input type="text" v-model.trim="form.room_number" @input="roomInput" placeholder="如：A栋204"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="设为默认地址">
                            <el-switch v-model="form.is_default" active-color="#1890ff" inactive-color="#ccc" :active-value="1" :inactive-value="0" class="mt_10"></el-switch>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <amap></amap>
                    </el-col>
                </el-row>
            </el-form>

            <!-- 地图控件 -->
            <div id="custom-amap" />
        </div>
        <template v-slot:footer>
            <div class="flex_cen">
                <el-button type="info" size="medium" @click="close">取 消</el-button>
                <!-- <el-button type="danger" size="medium" v-else @click="del">删 除</el-button> -->
                <el-button type="primary" size="medium" :loading="loading" @click="handelSave('addressForm')">保存地址</el-button>
            </div>
        </template>
    </el-dialog>
</template>

<script>
const AMap = window.AMap
export default {
	name: 'AMap',
	data() {
		return {
			id: '',
			loading: false,
			showLoading: false,
			defaultCity: '北京',
			// 地图对象
			map: null,
			// 定位默认地址 | 搜索后选择的地址
			formattedAddress: null,
			// 地址对应的经纬度信息
			position: {},
			// 检索函数对象
			placeSearch: null,
			// 检索结果数据数据
			searchInfoList: [],
			// 地图标记
			marker: '',
			// 地址解析（正向）
			geocoder: '',
			// 地址名称
			name: '',
			mapVisible: false,
			form: {
				formattedAddress: '',
				province: '',
				city: '',
				district: '',
				address: '',
				room_number: '',
				is_default: 0,
				lat: '',
				lng: ''
			},
			rules: {
				formattedAddress: [
					{ required: true, message: "请输入地址", trigger: "change" }
				],
				room_number: [
					{ required: true, message: "请输入门牌号", trigger: "change" }
				]
			}
		}
	},
	methods: {
		roomInput() {
			this.form.room_number = this.form.room_number.replace(/[^\u4E00-\u9FA5A-Za-z0-9]/g, '')
		},
		close() {
			this.handelclearInput()
			document.querySelector('#searchResultPanel').innerHTML = ''
			this.$refs.addressForm.resetFields()
			this.map.destroy()
			this.form = {
				formattedAddress: '',
				province: '',
				city: '',
				district: '',
				address: '',
				room_number: '',
				is_default: 0,
				lat: '',
				lng: ''
			}
			this.mapVisible = false
		},
		del() {
			this.$confirm('确定删除该地址？', '提示', {
				confirmButtonText: '确 定',
				cancelButtonText: '取 消',
				showClose: false,
				type: 'warning'
			}).then(() => {

			})
		},
		getMap() {
			this.showLoading = true
			this.$axios({
				url: `/ent/v3/working_place/${this.id}`,
				method: 'GET',
				hideLoading: true
			}).then(res => {
				this.showLoading = false
				if (res.code === 200) {
					this.form = res.data
					this.form.formattedAddress = res.data.province + res.data.city + res.data.district + res.data.address
					this.initMap()
				}
			})
		},
		//   初始化地图页面
		initMap() {
			this.map = new AMap.Map('custom-amap', {
				resizeEnable: true,
				// center: [ lng, lat ], //地图中心点
				zoom: 50
			})
			this.map.setFitView()//让地图自适应的显示在区域中

			// 添加检索提示
			this.addAMapAutocompletePlaceSearch()

			// 设置默认显示位置
			if(this.id) {
				let lng = this.form.lng
				let lat = this.form.lat
				this.map.setCenter([ lng, lat ])
				this.marker = new AMap.Marker({
					position: new AMap.LngLat(lng, lat),
					map: this.map
				})

				//构建信息窗体中显示的内容
				var info = []
				info.push(`<p class='fs_14 bold color_000'>${this.form.city}${this.form.district}</p>`)
				info.push(`<p class='fs_12 mt_3'>${this.form.address}</p>`)

				let infoWindow = new AMap.InfoWindow({
					anchor: 'bottom-center',
					offset: new AMap.Pixel(0, -35),
					content: info.join("")  //使用默认信息窗体框样式，显示信息内容
				})
				infoWindow.open(this.map, this.map.getCenter())
			}else {
				// 添加定位
				this.addAMapGeolocation()
			}

			// 添加鼠标点选地图选择地址
			// this.addAmapGeocoder()
		},
		// 添加maker
		setMaker() {
			this.marker = new AMap.Marker()
			this.map.add(this.marker)
			// 添加解析地理位置插件
			this.map.plugin('AMap.Geocoder', () => {
				// 异步加载插件
				this.geocoder = new AMap.Geocoder({
					city: this.defaultCity, // 默认：“全国”
					radius: 1000 // 范围，默认：500
				})
			})
		},
		// // 添加鼠标点选地图选择地址
		// addAmapGeocoder() {
		// 	// 添加maker
		// 	this.setMaker()
		// 	// 地图添加点击事件
		// 	this.map.on('click', e => {
		// 		const lnglat = [ e.lnglat.lng, e.lnglat.lat ]
		// 		this.marker.setPosition(lnglat)

		// 		this.geocoder.getAddress(lnglat, (status, result) => {
		// 			if (status === 'complete' && result.regeocode) {
		// 				const res = result.regeocode
		// 				const data = {
		// 					// 地址名称
		// 					adress: res.formattedAddress,
		// 					// 纬度lat
		// 					lat: lnglat[1],
		// 					// 经度lng
		// 					lng: lnglat[0]
		// 				}
		// 				this.formattedAddress = res.formattedAddress
		// 				this.position = data
		// 			} else {
		// 				console.log(JSON.stringify(result))
		// 			}
		// 		})
		// 	})
		// },
		// 添加自动定位
		addAMapGeolocation() {
			this.map.plugin('AMap.Geolocation', () => {
				const geolocation = new AMap.Geolocation({
					// 是否使用高精度定位，默认：true
					enableHighAccuracy: true,
					// 设置定位超时时间，默认：无穷大
					timeout: 10000,
					// 定位按钮的停靠位置的偏移量，默认：Pixel(10, 20)
					buttonOffset: new AMap.Pixel(20, 20),
					//  定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
					zoomToAccuracy: true,
					//  定位按钮的排放位置,  RB表示右下
					buttonPosition: 'RB'
				})
				// 获取当前位置
				this.map.addControl(geolocation)
				geolocation.getCurrentPosition()
				// 添加定位当前城市成功监听
				setTimeout(()=>{
					AMap.event.addListener(
						geolocation,
						'complete',
						this.onCurrentPositionComplete
					)
					// 添加定位当前城市发生错误监听
					AMap.event.addListener(
						geolocation,
						'error',
						this.onCurrentPositionError
					)
				}, 800)
			})
		},
		// 添加检索提示检索
		addAMapAutocompletePlaceSearch() {
			// 自动提示
			this.map.plugin('AMap.Autocomplete', () => {
				const auto = new AMap.Autocomplete({
					city: this.defaultCity,
					input: 'search-input'
				})
				// 添加检索监听
				setTimeout(()=>{
					AMap.event.addListener(auto, 'select', this.onSelectAutocomplete)
				}, 1000)
			})
			// 检索服务
			AMap.service([ 'AMap.PlaceSearch' ], () => {
				// 构造地点查询类
				this.placeSearch = new AMap.PlaceSearch({
					type: '', // 兴趣点类别
					pageSize: 5, // 单页显示结果条数
					pageIndex: 1, // 页码
					city: this.defaultCity, // 兴趣点城市
					citylimit: false, // 是否强制限制在设置的城市内搜索
					map: this.map, // 展现结果的地图实例
					panel: 'searchResultPanel', // 结果列表将在此容器中进行展示。
					autoFitView: true // 是否自动调整地图视野使绘制的 Marker点都处于视口的可见范围
				})
			})
			// 添加检索监听
			setTimeout(()=>{
				AMap.event.addListener(
					this.placeSearch,
					'listElementClick',
					this.onSelectSearch
				)
			}, 800)
		},
		// 定位当前城市成功回调
		onCurrentPositionComplete(res) {
			// 添加maker
			this.setMaker()
			const lnglat = [ res.position.lng, res.position.lat ]
			this.marker.setPosition(lnglat)
			this.formattedAddress = res.formattedAddress
			this.position = res.position

			//构建信息窗体中显示的内容
			var info = []
			info.push(`<p class='fs_14 bold color_000'>${res.formattedAddress}</p>`)
			// info.push(`<p class='fs_12 mt_3'>${this.form.address}</p>`)

			let infoWindow = new AMap.InfoWindow({
				anchor: 'bottom-center',
				offset: new AMap.Pixel(0, -35),
				content: info.join("")  //使用默认信息窗体框样式，显示信息内容
			})
			infoWindow.open(this.map, this.map.getCenter())
		},
		// 定位当前城市发生错误回调
		onCurrentPositionError(err) {
			console.log('定位失败', err)
		},
		// 按钮触发检索
		handelSearch() {
			this.placeSearch.search(this.form.address, (status, info) => {
				this.searchInfoList = info.poiList.pois
			})
		},
		// 选择自动提示数据事件回调
		onSelectAutocomplete(e) {
			const resEle = document.querySelector('.amap-sug-result')
			// 解决选择地址后下拉框不隐藏的问题,搭配css样式控制
			resEle.classList.add('hidden')
			this.form.address = e.poi.name
			this.handelSearch()
		},
		// 选择检索数据结果事件回调
		onSelectSearch(e) {
			const res = e.data
			this.formattedAddress = res.cityname + res.adname + res.address
			this.form.province = res.pname
			this.form.city = res.cityname
			this.form.district = res.adname
			this.form.address = res.address + res.name
			this.form.lat = res.location.lat
			this.form.lng = res.location.lng
			this.form.formattedAddress = res.pname + res.cityname + res.adname + res.address + res.name
			this.handelclearInput()
		},
		addressInput(value) {
			if(!value) {
				this.handelclearInput()
				return
			}
			const resEle = document.querySelector('.amap-sug-result')
			resEle.classList.remove('hidden')
		},
		// 清除input里的值，清除搜索结果，再次初始化map
		handelclearInput() {
			const resEle = document.querySelectorAll('.amap-sug-result')
			resEle.forEach(item=>{
				item.classList.add('hidden')
			})
			document.querySelector('#searchResultPanel').innerHTML = ''
		},
		// 保存当前选择的地址,分发事件
		handelSave(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					this.handelclearInput()
					if (this.id) {
						this.form.id = this.id
					}
					this.loading = true
					if (!this.form.lat) {
						this.$message.warning('请选择有效的地址')
						return
					}
					this.$axios({
						url: '/ent/v3/working_place',
						method: 'POST',
						hideLoading: true,
						data: this.form
					}).then(res => {
						this.loading = false
						if (res.code === 200) {
							this.mapVisible = false
							this.$emit('callback', this.form.address)
						}
					})
				}
			})
		}
	}
}
</script>

<style lang="less" scoped>
#amap-container {
    position: relative;
    #searchResultPanel {
        position: absolute;
        width: 100%;
        left: 0;
        top: 100%;
        z-index: 99;
    }
}
.el-input__clear {
    line-height: 34px;
    // top: 20px;
}
#custom-amap {
    height: 250px;
    width: 100%;
    margin-top: 10px;
    border: 1px solid #ccc;
}
.input-with {
    // position: fixed;
    // top: 40px;
    z-index: 1;
    // width: 580px;
}
.address {
    color: #373737;
}
.amap-sug-result {
    z-index: 99999;
}
</style>
